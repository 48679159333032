import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  BackgroundImages,
  FishermanHeader,
  MarkdownContent,
  Image,
  OutboundLink,
  FishermanIcon,
  FadeReveal,
  Location,
  HoursOfOperation,
  Map,
} from "@bluefin/components";
import { Button, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage, bookingUrl } =
      data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.robertandrew.com/contact/"}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Section className={"default-hero-section"}>
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              carouselProps={{
                leftArrowIcon: "arrow-left",
                rightArrowIcon: "arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <div className={"content-container"}>
                <FishermanHeader
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "contact_hero_header",
                    defaultValue: "Contact",
                  })}
                />
                <FishermanHeader
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "contact_hero_subheader",
                    defaultValue: "Enhancing The Beauty",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "contact_hero_description",
                  })}
                />
              </div>
            </BackgroundImages>
          </Section>
          <Section className={"book-now-section"}>
            <Image
              background={true}
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentIdentifier: "book_now_background_image",
                numToSelect: 1,
              })}
            >
              <FishermanHeader
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "book_now_section_header",
                  defaultValue: "Book Your Appointment",
                })}
              />
              <MarkdownContent
                className={"book-now-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "book_now_section_description",
                  defaultValue: "",
                })}
              />
              <Button
                secondary={true}
                as={OutboundLink}
                to={bookingUrl.url}
                event={{
                  category: "Booking",
                  action: "Schedule Appointment Intent",
                }}
                icon={<FishermanIcon iconName={"arrow-right"} />}
                labelPosition={"right"}
                content={bookingUrl.title}
              />
            </Image>
          </Section>
          <Section className={"contact-info-section"}>
            <Grid stackable={true} className={"contact-info-container"}>
              <Grid.Column width={8} className={"info-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <Location
                    phone={fishermanBusiness.primaryLocation.phoneNumber}
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                    businessName={fishermanBusiness.name}
                    showBusinessName={false}
                  />
                  <p children={"Opening Hours:"} />
                  <HoursOfOperation
                    hours={fishermanBusiness.primaryLocation.hours}
                    displayOption={"grouped"}
                    inlineHours={true}
                    truncateLabels={true}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"map-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <Map
                    businessName={fishermanBusiness.name}
                    address={{
                      street: fishermanBusiness.primaryLocation.street,
                      city: fishermanBusiness.primaryLocation.city,
                      state: fishermanBusiness.primaryLocation.state,
                      zip: fishermanBusiness.primaryLocation.zipCode,
                    }}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        street
        city
        state
        zipCode
        hours {
          day
          open
          close
        }
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
  }
`;
